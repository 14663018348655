import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    orders: [],
    order: {}


  };


  const slice = createSlice({
    name: 'order',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getOrdersSuccess(state, action) {
        state.isLoading = false;
        state.orders = action.payload;
      },

      
      getOrderSuccess(state, action) {
        state.isLoading = false;
        state.order = action.payload;
      },

      resetOrder(state, action){
        state.order = {}
      }
  

      

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getOrders() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/customer/order');
        dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        console.log(response.data,'response')
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function getOrderById(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/customer/order/${id}`);
        dispatch(slice.actions.getOrderSuccess(response.data));
        console.log(response.data,'response')
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  export function getOrderByIdReset(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        dispatch(slice.actions.resetOrder());
        const response = await axios.get(`/api/customer/order/${id}`);
        dispatch(slice.actions.getOrderSuccess(response.data));
        console.log(response.data,'response')
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  
  



  


 
  